<template>
  <base-popup
    title="Оценка качества и безопасности медицинской деятельности структурных подразделений"
    :icon="UtilGetIcon('view-CheckLists')"
    @save="save"
    v-model="dialog"
    v-bind="$attrs"
  >
    <v-container v-if="item">
      <v-textarea
        :value="item.ResultText"
        @change="item.ResultText = $event"
        label="Результаты внутреннего контроля качества и безопасности медицинской деятельности"
      ></v-textarea>
      <v-textarea
        :value="item.ViolationsText"
        @change="item.ViolationsText = $event"
        label="Выявленные несоответствия"
      ></v-textarea>
      <v-textarea
        :value="item.TasksText"
        @change="item.TasksText = $event"
        label="Перечень корректирующих мер"
      ></v-textarea>
      <v-textarea
        :value="item.ExecutionText"
        @change="item.ExecutionText = $event"
        label="Сведения о выполнении"
      ></v-textarea>
    </v-container>
  </base-popup>
</template>
<script>
import BasePopup from "@/layouts/BasePopup.vue";

export default {
  name: "consolidated-report-menu",
  components: { BasePopup },
  props: {
    item: {
      type: Object,
      default: null,
    },
  },
  data: function () {
    return {
      dialog: false,
    };
  },
  watch: {
    item(val) {
      if (val) {
        this.dialog = true;
      }
    },
    dialog(val) {
      if (!val && this.item) {
        this.$emit("update:item", null);
      }
    },
  },
  methods: {
    save() {
      this.$emit("save");

      this.dialog = false;
    },
  },
};
</script>
