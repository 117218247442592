import Api from "./Api";

export default {
  async get() {
    return Api().get("common/GetSummaryReports");
  },
  show(id) {
    return Api().get("common/GetSummaryReport", { params: { id, step: 1 } });
  },
  async delete(id) {
    return Api().delete("common/DeleteSummaryReport", {
      params: { id },
    });
  },

  updateStatus(id, status) {
    return Api().put("common/UpdateSummaryReportStatus", { id, status });
  },

  step1: {
    async show(id) {
      return Api().get("common/GetSummaryReport", { params: { id, step: 1 } });
    },
    async create(report) {
      return Api().post("common/CreateSummaryReport", report);
    },
    async update(report) {
      return Api().put("common/UpdateSummaryReport", report);
    },
  },
  step2: {
    async show(id, params) {
      return Api().get("common/GetSummaryReport", {
        params: { id, step: 2, actual: !!params?.query?.actual },
      });
    },
    async create(report) {
      return Api().post("common/CreateSummaryReport", report);
    },
    async update(report) {
      return Api().put("common/UpdateSummaryReport", report);
    },
  },
  step3: {
    async show(id, params) {
      return Api().get("common/GetSummaryReport", {
        params: { id, step: 3, actual: !!params?.query?.actual },
      });
    },
    async update(report) {
      return Api().put("common/UpdateSummaryReport", report);
    },
  },
  step4: {
    async show(id, params) {
      return Api().get("common/GetSummaryReport", {
        params: { id, step: 4, actual: !!params?.query?.actual },
      });
    },
    async update(report) {
      return Api().put("common/UpdateSummaryReport", report);
    },
  },
  step5: {
    async show(id, params) {
      return Api().get("common/GetSummaryReport", {
        params: { id, step: 5, actual: !!params?.query?.actual },
      });
    },
    async update(report) {
      return Api().put("common/UpdateSummaryReport", report);
    },
  },
  step6: {
    async show(id, params) {
      return Api().get("common/GetSummaryReport", {
        params: { id, step: 6, actual: !!params?.query?.actual },
      });
    },
    async update(report) {
      return Api().put("common/UpdateSummaryReport", report);
    },
  },
  step7: {
    async show(id, params) {
      return Api().get("common/GetSummaryReport", {
        params: { id, step: 7, actual: !!params?.query?.actual },
      });
    },
    async update(report) {
      return Api().put("common/UpdateSummaryReport", report);
    },
    updateSummaryReportSettings(id, settingId) {
      return Api().put("common/UpdateSummaryReportSettings", {
        Id: id,
        SettingId: settingId,
      });
    },
  },
};
