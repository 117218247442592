<template>
  <base-panel
    :title="title"
    icon="medexpert-reports"
    headerTogether
    class="block__content"
  >
    <div>
      <div class="mx-10 mt-0"><slot></slot></div>
      <div class="d-flex justify-center mt-6">
        <v-btn v-if="getStep() > 1" color="primary" class="mr-5" @click="back">
          <m-icon icon="mdi-arrow-left-thin" left></m-icon>
          Назад
        </v-btn>
        <v-btn v-if="getStep() < 7" color="primary" @click="forward">
          Далее
          <m-icon icon="mdi-arrow-right-thin" right></m-icon>
        </v-btn>
      </div>
    </div>
  </base-panel>
</template>
<script>
import basePanel from "@/layouts/BasePanel.vue";
export default {
  name: "step-wrapper",
  components: { basePanel },
  inject: ["back", "forward", "getStep"],
  props: {
    title: {
      type: String,
      default: "",
    },
  },
};
</script>
